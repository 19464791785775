// This would most likely be in a language file
import i18n from "@/i18n";
export const TEMPLATES_MAP: any = {
  alpha: "Alphanumeric characters only.",
  minValue: "Must have a length no less than {{min}}.",
  maxValue: "Must have a length less then than {{max}}.",
  minLength: "Must have a length no less than {{min}}.",
  maxLength: "Must have a length less then than {{max}}.",
  required: "Required field.",
  email: "Enter a valid email",
  passwordConfirmation: "Passwords must be identical.",
  zipCode: "Zip code is not valid for country {{value}}.",
  pdfRequired: "File must be PDF.",
  numeric: "Must be numeric",
  iban: "Invalid IBAN",
  bic: "Invalid BIC",
  duplicateVehicle: "Duplicate Vehicle",
  sameAsPassword: "Passwords must be identical",
  minValueCustom: "Der Betrag darf nicht negativ sein.",
  maxValue100: "Der Text im Feld Titel ist zu lange - bitte maximal 100 Zeichen eingeben",
  maxValue500: "Der Text im Feld Beschreibung ist zu lange - bitte maximal 500 Zeichen eingeben",
  checkDateRegex: i18n.t("general.invalid_date_format"),
};
