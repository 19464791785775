










import { template, templateSettings } from "lodash";
import { Vue, Component, Prop } from "vue-property-decorator";
import { VNode } from "vue";
import { TEMPLATES_MAP } from "@/constants/Validations";

// Curly brace syntax
templateSettings.interpolate = /{{([\s\S]+?)}}/g;

@Component
export default class Validation extends Vue {
  public name = "Validation";

  @Prop({ required: true })
  public validations!: any;

  @Prop({ required: false, default: () => false })
  public showEachErrors!: any;

  public get errors(): Array<any> {
    if (!this.invalid) {
      return [];
    }
    return Object.keys(this.validations.$params).reduce((errors: Array<string>, validator) => {
      if (!this.validations[validator]) {
        const compiled = template(TEMPLATES_MAP[validator]);
        const error: string = compiled(this.validations.$params[validator]);
        errors.push(error);
      } else if (validator == "$each" && this.showEachErrors) {
        Object.keys(this.validations.$each.$iter).reduce((previousValue: [], currentValue: any) => {
          Object.keys(this.validations.$each.$iter[currentValue].$params).reduce((errorsEach: Array<string>, validatorEach: any) => {
            if (!this.validations.$each.$iter[validatorEach]) {
              const compiled = template(TEMPLATES_MAP[validatorEach]);
              const errorNew: string = compiled(this.validations.$params[validatorEach]);
              errors.push(errorNew);
            }
            return errorsEach;
          }, []);
          return previousValue;
        }, []);
      }
      return errors;
    }, []);
  }

  public get invalid(): boolean {
    return this.validations.$dirty && this.validations.$invalid;
  }

  public render(): VNode[] | undefined {
    return this.$scopedSlots.default?.({
      errors: this.errors,
      invalid: this.invalid,
    });
  }
}
